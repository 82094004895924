import { convertAgeToHalfYearInMonths } from '../vitals-utils';
import { LMSParamsMap } from './lms-params.type';
import { zScoreFromMeasurement } from './z-score-to-percentile';

const CmPerInch = 2.54;

// Data comes from this file https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5451269/
const maleDownSyndromeChildHeightLMSParams: LMSParamsMap = {
  2: { lambda: 0.453, mu: 81.511, sigma: 0.044 },
  2.5: { lambda: 0.453, mu: 84.522, sigma: 0.045 },
  3: { lambda: 0.453, mu: 87.222, sigma: 0.046 },
  3.5: { lambda: 0.453, mu: 89.966, sigma: 0.046 },
  4: { lambda: 0.453, mu: 92.864, sigma: 0.047 },
  4.5: { lambda: 0.453, mu: 96.039, sigma: 0.047 },
  5: { lambda: 0.453, mu: 99.492, sigma: 0.048 },
  5.5: { lambda: 0.453, mu: 103.057, sigma: 0.048 },
  6: { lambda: 0.453, mu: 106.618, sigma: 0.048 },
  6.5: { lambda: 0.453, mu: 109.994, sigma: 0.049 },
  7: { lambda: 0.453, mu: 113.134, sigma: 0.049 },
  7.5: { lambda: 0.453, mu: 116.108, sigma: 0.049 },
  8: { lambda: 0.453, mu: 118.903, sigma: 0.049 },
  8.5: { lambda: 0.453, mu: 121.473, sigma: 0.049 },
  9: { lambda: 0.453, mu: 123.774, sigma: 0.049 },
  9.5: { lambda: 0.453, mu: 125.896, sigma: 0.049 },
  10: { lambda: 0.453, mu: 127.967, sigma: 0.049 },
  10.5: { lambda: 0.453, mu: 130.074, sigma: 0.049 },
  11: { lambda: 0.453, mu: 132.308, sigma: 0.049 },
  11.5: { lambda: 0.453, mu: 134.804, sigma: 0.049 },
  12: { lambda: 0.453, mu: 137.688, sigma: 0.048 },
  12.5: { lambda: 0.453, mu: 140.9, sigma: 0.048 },
  13: { lambda: 0.453, mu: 144.212, sigma: 0.047 },
  13.5: { lambda: 0.453, mu: 147.259, sigma: 0.047 },
  14: { lambda: 0.453, mu: 149.772, sigma: 0.046 },
  14.5: { lambda: 0.453, mu: 151.719, sigma: 0.046 },
  15: { lambda: 0.453, mu: 153.148, sigma: 0.045 },
  15.5: { lambda: 0.453, mu: 154.182, sigma: 0.045 },
  16: { lambda: 0.453, mu: 154.895, sigma: 0.045 },
  16.5: { lambda: 0.453, mu: 155.347, sigma: 0.045 },
  17: { lambda: 0.453, mu: 155.599, sigma: 0.045 },
  17.5: { lambda: 0.453, mu: 155.703, sigma: 0.045 },
  18: { lambda: 0.453, mu: 155.734, sigma: 0.045 },
  18.5: { lambda: 0.453, mu: 155.846, sigma: 0.045 },
  19: { lambda: 0.453, mu: 156.016, sigma: 0.044 },
  19.5: { lambda: 0.453, mu: 156.239, sigma: 0.044 },
  20: { lambda: 0.453, mu: 156.491, sigma: 0.044 },
};

const femaleDownSyndromeChildHeightLMSParams: LMSParamsMap = {
  2: { lambda: 1.957, mu: 79.361, sigma: 0.044 },
  2.5: { lambda: 1.957, mu: 82.438, sigma: 0.045 },
  3: { lambda: 1.957, mu: 85.323, sigma: 0.046 },
  3.5: { lambda: 1.957, mu: 88.601, sigma: 0.047 },
  4: { lambda: 1.957, mu: 92.089, sigma: 0.048 },
  4.5: { lambda: 1.957, mu: 95.505, sigma: 0.048 },
  5: { lambda: 1.957, mu: 98.615, sigma: 0.049 },
  5.5: { lambda: 1.957, mu: 101.419, sigma: 0.049 },
  6: { lambda: 1.957, mu: 104.019, sigma: 0.05 },
  6.5: { lambda: 1.957, mu: 106.532, sigma: 0.05 },
  7: { lambda: 1.957, mu: 109.09, sigma: 0.05 },
  7.5: { lambda: 1.957, mu: 111.808, sigma: 0.05 },
  8: { lambda: 1.957, mu: 114.754, sigma: 0.05 },
  8.5: { lambda: 1.957, mu: 118.055, sigma: 0.05 },
  9: { lambda: 1.957, mu: 121.611, sigma: 0.05 },
  9.5: { lambda: 1.957, mu: 125.314, sigma: 0.05 },
  10: { lambda: 1.957, mu: 128.835, sigma: 0.049 },
  10.5: { lambda: 1.957, mu: 131.843, sigma: 0.049 },
  11: { lambda: 1.957, mu: 134.302, sigma: 0.049 },
  11.5: { lambda: 1.957, mu: 136.285, sigma: 0.048 },
  12: { lambda: 1.957, mu: 137.855, sigma: 0.048 },
  12.5: { lambda: 1.957, mu: 139.081, sigma: 0.047 },
  13: { lambda: 1.957, mu: 140.046, sigma: 0.047 },
  13.5: { lambda: 1.957, mu: 140.843, sigma: 0.047 },
  14: { lambda: 1.957, mu: 141.517, sigma: 0.047 },
  14.5: { lambda: 1.957, mu: 142.094, sigma: 0.046 },
  15: { lambda: 1.957, mu: 142.59, sigma: 0.046 },
  15.5: { lambda: 1.957, mu: 142.985, sigma: 0.046 },
  16: { lambda: 1.957, mu: 143.275, sigma: 0.046 },
  16.5: { lambda: 1.957, mu: 143.492, sigma: 0.046 },
  17: { lambda: 1.957, mu: 143.667, sigma: 0.046 },
  17.5: { lambda: 1.957, mu: 143.794, sigma: 0.046 },
  18: { lambda: 1.957, mu: 143.852, sigma: 0.046 },
  18.5: { lambda: 1.957, mu: 143.912, sigma: 0.046 },
  19: { lambda: 1.957, mu: 144.109, sigma: 0.046 },
  19.5: { lambda: 1.957, mu: 144.417, sigma: 0.045 },
  20: { lambda: 1.957, mu: 144.766, sigma: 0.045 },
};

export const getDownSyndromeHeightPercentile = (
  gender: string,
  ageInMonths: number,
  length: number,
) => {
  const lengthInCm = length * CmPerInch;
  const ageInHalfMonths = convertAgeToHalfYearInMonths(ageInMonths);

  const dsHeightLMSParams =
    gender === 'Female'
      ? femaleDownSyndromeChildHeightLMSParams
      : maleDownSyndromeChildHeightLMSParams;

  return zScoreFromMeasurement(lengthInCm, ageInHalfMonths, dsHeightLMSParams);
};
