import { Validators } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { Patient, PatientSelectors } from '@app/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { DropdownItem } from '@app/shared/components/dropdown/dropdown-item';
import { DynamicFormGroup } from '@app/utils/forms/base';

import { HelpRequestService } from './help-request.service';

export class HelpRequestForm extends DynamicFormGroup {
  maxDetailsLength = 500;
  usStates: DropdownItem[];
  helpFlowTypes: DropdownItem[];

  constructor(
    private helpRequestService: HelpRequestService,
    private patientSelectors: PatientSelectors,
    private launchDarklyService: LaunchDarklyService,
    private unsubscribe$: Subject<void>,
  ) {
    super();
    this.addControls();
    this.subscribeToStreams();
  }

  private updateHelpflowType(value: string) {
    this.controls.get('helpflowType').patchValue(value);
  }

  private updateLicensingBody(id: string) {
    this.controls.get('licensingBodyId').patchValue(id);
  }

  private subscribeToStreams() {
    combineLatest([
      this.launchDarklyService.variation$(
        FeatureFlagNames.helpOutRequestResults,
        false,
      ),
      this.helpRequestService.getHelpFlowTypes(),
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([flag, helpFlowTypes]: [boolean, DropdownItem[]]) =>
          helpFlowTypes.filter(type =>
            !flag ? type.value !== 'request_order_results' : true,
          ),
        ),
        tap(types => (this.helpFlowTypes = types)),
        tap(types => this.updateHelpflowType(types[0].value)),
      )
      .subscribe();

    this.patientSelectors.patient
      .pipe(
        takeUntil(this.unsubscribe$),
        map((patient: Patient) => {
          this.controls
            .get('callbackName')
            .patchValue(
              this.generateDefaultCallbackName(patient.preferredName),
            );
          this.controls
            .get('callbackNumber')
            .patchValue(patient.contactPhone.number);
        }),
      )
      .subscribe();
    this.setLicensingBodyAndStates();
  }

  private generateDefaultCallbackName(preferredName: string): string {
    return `${preferredName} (Patient)`;
  }

  private setLicensingBodyAndStates() {
    this.helpRequestService
      .getLicensingBodyAndStates()
      .pipe(
        takeUntil(this.unsubscribe$),
        map(({ licensingBodyId, states }) => {
          this.usStates = states;
          this.updateLicensingBody(licensingBodyId);
        }),
      )
      .subscribe();
  }

  private addControls() {
    const controls = [
      { name: 'helpflowType', validators: [Validators.required] },
      { name: 'licensingBodyId', validators: [Validators.required] },
      { name: 'details' },
      { name: 'callerOnTheLine', defaultValue: true },
      { name: 'callbackName' },
      {
        name: 'callbackNumber',
        validators: {
          validators: [
            Validators.pattern('^[0-9]{3}-*[0-9]{3}-*[0-9]{4}$'),
            Validators.minLength(10),
            Validators.maxLength(12),
          ],
          updateOn: <any>'blur',
        },
      },
    ];
    controls.forEach(control => this.addControl(control));
  }
}
