import { InjectionToken } from '@angular/core';

export interface Theme {
  name: string;
  class?: string;
  properties?: any;
}

export const ACTIVE_THEME = new InjectionToken('ACTIVE_THEME');

export const metropolitanMDTheme: Theme = {
  name: 'metropolitanMD',
  class: 'metropolitan-md-theme',
  properties: {
    '--base-font-size': '14px',
  },
};

export const onemTheme: Theme = {
  name: 'onem',
  class: 'onem-theme',
  properties: {
    '--base-font-size': '16px',
    '--mdc-layout-grid-gutter-desktop': '16px',
    '--mdc-layout-grid-margin-desktop': '16px',
    '--mdc-layout-grid-column-width-desktop': '72px',
    '--mdc-typography-font-family':
      'Inter, Tahoma, Geneva, Verdana, sans-serif',
    '--mdc-typography-body2-font-size': '14px',
    '--mdc-typography-body1-font-size': '16px',
    '--mdc-typography-button-letter-spacing': '0',
    '--mdc-theme-primary': '#4570c6',
    // temporary variables
    '--card-title-background-color': '#1e988b',
  },
};

export const jadeOnemTheme: Theme = {
  ...onemTheme,
  properties: {
    ...onemTheme.properties,
    '--card-title-background-color': '#045B53',
  },
};

export const availableThemes = [onemTheme, jadeOnemTheme];

export const isSupportedTheme = (theme: Theme) => {
  return availableThemes.includes(theme);
};
