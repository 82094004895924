import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem as BaseMenuItem } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SplitButtonStatesService } from '@app/core/services/split-button-states.service';
import { filterTruthy } from '@app/utils';

type ButtonVariant = 'primary' | 'secondary';

export interface MenuItem extends BaseMenuItem {
  showToolTip?: boolean;
  toolTipMessage?: string;
}

@Component({
  selector: 'omg-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss'],
  animations: [
    trigger('overlayAnimation', [
      state(
        'void',
        style({
          transform: 'translateY(5%)',
          opacity: 0,
        }),
      ),
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        }),
      ),
      transition('void => visible', animate('{{showTransitionParams}}')),
      transition('visible => void', animate('{{hideTransitionParams}}')),
    ]),
  ],
})
export class SplitButtonComponent implements OnInit, OnDestroy {
  @Input() items: MenuItem[];
  @Input() icon: string;
  @Input() iconPos = 'left';
  @Input() label: string;
  @Input() type = 'button';
  @Input() style: any;
  @Input() variant: ButtonVariant = 'secondary';
  @Input() menuStyle: any;
  @Input() menuStyleClass: string;
  @Input() disabled: boolean;
  @Input() disabledMenu: boolean;
  @Input() disabledLabel: boolean;
  @Input() tabindex: number;
  @Input() appendTo = 'body';
  @Input() dir: string;
  @Input() showTransitionOptions = '225ms ease-out';
  @Input() hideTransitionOptions = '195ms ease-in';

  @Output() labelClick: EventEmitter<any> = new EventEmitter();
  @Output() dropdownClick: EventEmitter<any> = new EventEmitter();

  @ViewChild('container', { static: true }) containerViewChild: ElementRef;
  @ViewChild('defaultbtn', { static: true }) buttonViewChild: ElementRef;

  overlay: HTMLDivElement;

  public overlayVisible = false;
  public documentClickListener: any;
  public documentScrollListener: any;
  public dropdownClicked: boolean;
  public shown: boolean;

  private unsubscribe = new Subject();
  private nativeClassName = '';

  documentResizeListener: any;
  zIndex: number;

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    public router: Router,
    public cd: ChangeDetectorRef,
    private splitButtonStateService: SplitButtonStatesService,
  ) {
    this.zIndex = DomHandler.zindex;
  }

  ngOnInit() {
    this.onDropdownClick();
    this.onGlobalSplitButtonClick();
  }

  onDefaultButtonClick($event: Event) {
    $event.stopPropagation();
    this.labelClick.emit($event);
  }

  itemClick($event: Event, item: MenuItem) {
    if (item.disabled) {
      $event.preventDefault();
      return;
    }

    if (!item.url) {
      $event.preventDefault();
    }

    if (item.command) {
      item.command({
        originalEvent: $event,
        item,
      });
    }

    this.overlayVisible = false;
  }

  show() {
    this.overlayVisible = !this.overlayVisible;
  }

  onOverlayAnimationStart($event: AnimationEvent) {
    switch ($event.toState) {
      case 'visible':
        this.overlay = $event.element;
        this.appendOverlay();
        this.overlay.style.zIndex = String(++this.zIndex);
        this.alignOverlay();
        this.bindDocumentClickListener();
        this.bindDocumentResizeListener();
        this.bindDocumentScrollListener();
        break;

      case 'void':
        this.onOverlayHide();
        break;
    }
  }

  onDropdownButtonClick($event: Event) {
    $event.stopPropagation();
    this.dropdownClick.emit($event);
    this.dropdownClicked = true;
    this.show();
  }

  private onDropdownClick() {
    this.dropdownClick
      .pipe(filterTruthy(), takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.nativeClassName = this.nativeClassName
          ? this.nativeClassName
          : this.el.nativeElement.className;
        this.splitButtonStateService.registerSplitButtonClick(
          this.el.nativeElement.className,
        );
      });
  }

  private onGlobalSplitButtonClick() {
    this.splitButtonStateService.splitButtonClicked
      .pipe(filterTruthy(), takeUntil(this.unsubscribe))
      .subscribe((uniqueClassName: string) => {
        if (uniqueClassName !== this.nativeClassName) {
          this.dropdownClicked = true;
          this.overlayVisible = false;
          this.cd.markForCheck();
        }
      });
  }

  alignOverlay() {
    if (this.appendTo) {
      DomHandler.absolutePosition(
        this.overlay,
        this.containerViewChild.nativeElement,
      );
    } else {
      DomHandler.relativePosition(
        this.overlay,
        this.containerViewChild.nativeElement,
      );
    }
  }

  appendOverlay() {
    if (this.appendTo) {
      if (this.appendTo === 'body') {
        document.body.appendChild(this.overlay);
      } else {
        DomHandler.appendChild(this.overlay, this.appendTo);
      }

      this.overlay.style.minWidth = `${DomHandler.getWidth(
        this.el.nativeElement.children[0],
      )}px`;
    }
  }

  restoreOverlayAppend() {
    if (this.overlay && this.appendTo) {
      this.el.nativeElement.appendChild(this.overlay);
    }
  }

  bindDocumentScrollListener() {
    if (!this.documentScrollListener) {
      this.documentScrollListener = this.alignOverlay.bind(this);
      window.addEventListener('scroll', this.documentScrollListener, true);
    }
  }

  bindDocumentClickListener() {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen(
        'document',
        'click',
        () => {
          if (this.dropdownClicked) {
            this.dropdownClicked = false;
            this.overlayVisible = false;
            this.unbindDocumentClickListener();
            this.cd.markForCheck();
          }
        },
      );
    }
  }

  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }

  bindDocumentResizeListener() {
    this.documentResizeListener = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.documentResizeListener);
  }

  unbindDocumentScrollListener() {
    if (this.documentScrollListener) {
      window.removeEventListener('scroll', this.documentScrollListener, true);
      this.documentScrollListener = null;
    }
  }

  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      window.removeEventListener('resize', this.documentResizeListener);
      this.documentResizeListener = null;
    }
  }

  onWindowResize() {
    this.overlayVisible = false;
  }

  onOverlayHide() {
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.unbindDocumentScrollListener();

    this.overlay = null;
  }

  ngOnDestroy() {
    this.restoreOverlayAppend();
    this.onOverlayHide();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
