import {
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  SecurityContext,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PrimeNGConfig } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';

@Directive({
  selector: '[omgTooltip]',
})
export class TooltipDirective extends Tooltip implements OnInit, OnChanges {
  @Input()
  public tooltipText: string;

  @Input()
  public showTooltip = true;

  constructor(
    el: ElementRef,
    zone: NgZone,
    private sanitizer: DomSanitizer,
    private primengConfig: PrimeNGConfig,
  ) {
    super(el, zone, primengConfig);

    this.showDelay = 250;
  }

  public ngOnInit() {
    this.disabled = !this.showTooltip;
    this.setSanitizedText(this.tooltipText);
    this.primengConfig.ripple = true;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.showTooltip) {
      this.disabled = !changes.showTooltip.currentValue;
    }

    if (changes.tooltipText) {
      this.setSanitizedText(changes.tooltipText.currentValue);
    }
  }

  private setSanitizedText(rawText: string) {
    const safeHTML = this.sanitizer.sanitize(SecurityContext.HTML, rawText);

    this.text = <string>safeHTML;
  }
}
