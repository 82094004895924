import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { dateIsString, isValid, parseISO } from '@app/utils';

import { ButtonGroupOption } from '../button-group/button-group.component';

@Component({
  selector: 'omg-quick-date-picker',
  templateUrl: './quick-date-picker.component.html',
  styleUrls: ['./quick-date-picker.component.scss'],
})
export class QuickDatePickerComponent implements OnInit, OnDestroy {
  @Input() options: ButtonGroupOption[];
  @Input() date: FormControl;
  @Input() helperText: string;
  @Input() handleErrors = true;

  quickDateSelector: FormControl;
  manualDateSelector: FormControl;

  private unsubscribe$ = new Subject();
  buttonOptions: { value: string; label: string }[];

  /* istanbul ignore next */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.buttonOptions = this.options?.map(option => ({
      ...option,
      value: option.value.toString(),
    }));

    if (this.date) {
      this.quickDateSelector = this.formBuilder.control(
        this.convertToDate(this.date.value)?.toString(),
      );
      this.manualDateSelector = this.formBuilder.control(
        this.convertToDate(this.date.value),
      );
      this.bindDateInputs();
    }
  }

  convertToDate(date: Date | string): Date {
    if (dateIsString(date)) {
      const parsedDate = parseISO(`${date}`);
      return isValid(parsedDate) ? parsedDate : null;
    }
    return isValid(date) ? new Date(date) : null;
  }

  private bindDateInputs() {
    this.quickDateSelector.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(newDateString => {
        const newDate = new Date(newDateString);
        this.manualDateSelector.setValue(newDate, {
          emitEvent: false,
        });
        this.date.setValue(newDate);
      });

    this.manualDateSelector.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(newDate => {
        this.quickDateSelector.setValue(newDate?.toString() || null, {
          emitEvent: false,
        });
        this.date.setValue(newDate);
      });

    this.date.valueChanges
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe(newDate => {
        if (newDate !== this.manualDateSelector.value) {
          this.manualDateSelector.setValue(this.convertToDate(newDate), {
            emitEvent: false,
          });
        }
        if (newDate !== this.quickDateSelector.value) {
          this.quickDateSelector.setValue(
            this.convertToDate(newDate)?.toString(),
            {
              emitEvent: false,
            },
          );
        }
      });
  }

  modifyDate($event: Date) {
    this.manualDateSelector.setValue($event, {
      emitEvent: false,
    });
    this.date.setValue($event);
  }
}
