export const idleTimeoutMs = 5 * 60000; // 5mins

export const documentEventNames = [
  'click',
  'wheel',
  'scroll',
  'mousemove',
  'keyup',
];
export const windowEventNames = ['resize', 'scroll', 'mousemove'];

export const userOfflineIfHeartbeatPastSeconds = 20;

export const houseCleaningIntervalMs = 5000;

export const heartbeatIntervalMs = 10000;
