import { Patient, PatientWarnings } from './patient.type';

export const basicPatientDetails = (p: Patient) =>
  `${p.preferredName} ${p.lastName.charAt(0)} (${p.age}yo)`;

export const formattedAge = (p: Patient) =>
  p && (p.age >= 3 ? `${p.age} yo` : p.infantAge);

export const formatAge = (ageInMonths: number) => {
  const weeksInAMonth = 4.34524;
  let displayAge = '';
  if (ageInMonths >= 24) {
    displayAge = `${Math.floor(ageInMonths / 12)} yo`;
  } else {
    let rounded_months = Math.floor(ageInMonths);
    let rounded_weeks = Math.floor(
      (ageInMonths - rounded_months) * weeksInAMonth,
    );
    if (rounded_weeks === 4) {
      rounded_weeks = 0;
      rounded_months += 1;
    }
    displayAge = `${rounded_months} mo, ${rounded_weeks} wk`;
  }
  return displayAge;
};

export const isUnderTwenty = (p: Patient) => p && p.age < 20;

export const isTwentyOneOrOver = (p: Patient) => p && p.age >= 21;

export const isInfant = (p: Patient) => p && p.age < 2;

export const isMinor = (p: Patient) => p && p.age < 18;

export const isTeen = (p: Patient) => p && 13 <= p.age && p.age < 18;

export const isPreteen = (p: Patient) => p && 10 <= p.age && p.age <= 12;

export const hasIncompleteDemographics = (p: Patient) =>
  p &&
  !(
    p.dateOfBirth &&
    p.firstName &&
    p.lastName &&
    p.gender &&
    (p.primaryAddress || p.addresses.length > 0)
  );

export const acceptsDigitalCommunications = (p: Patient) =>
  p && !!p.patientPreferences && p.patientPreferences.acceptsClinicalEmails;

export const limitedAccessMembership = (p: Patient) =>
  p && p.membership && p.membership.limitedAccess;

export const expiredMembership = (p: Patient) =>
  p && p.membership && !p.membership.active;

const messages = {
  noDigitalCommunications: 'Patient opted out of digital communications.',
  limitedAccessMember:
    'Patient has limited access membership and cannot reply.',
  expiredMember: 'Patient cannot reply until their membership is renewed.',
  teenMinor:
    'Patient is a teenage minor. Legally protected info might be seen by a guardian.',
  preTeenMinor:
    'Patient is a pre-teen minor. Messages might be seen by a guardian.',
};

export const buildPatientWarnings = (p: Patient): PatientWarnings => ({
  ...(isTeen(p) ? { teenMinor: messages.teenMinor } : {}),
  ...(isPreteen(p) ? { preTeenMinor: messages.preTeenMinor } : {}),
  ...(expiredMembership(p) ? { expiredMember: messages.expiredMember } : {}),
  ...(limitedAccessMembership(p)
    ? { limitedAccessMember: messages.limitedAccessMember }
    : {}),
  ...(!acceptsDigitalCommunications(p)
    ? { noDigitalCommunications: messages.noDigitalCommunications }
    : {}),
});
