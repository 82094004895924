import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Patient, PatientSelectors } from '@app/core';
import { Summary } from '@app/features/summaries/shared/summaries.type';
import { DropdownItem } from '@app/shared';

import { ToastMessageService } from '../../shared/components/toast';
import {
  HelpFlowType,
  HelpFlowTypeResponse,
  HelpRequestMutationResponse,
  USState,
  USStatesResponse,
} from './help-request.type';

export interface HelpRequestOptions {
  patientId: number;
  linkedNoteId?: number;
  summary?: Partial<Summary>;
  actionUrl?: string;
}
export interface HelpRequestDetails {
  options: HelpRequestOptions;
  helpflowType: string;
  details: string;
  licensingBodyId: string;
  callerOnTheLine: boolean;
  callbackName?: string;
  callbackNumber?: string;
  urgency?: string;
  facility?: string;
}

const GetUSStatesQuery = gql`
  query States {
    states {
      id
      name
      shortName
    }
  }
`;

const GetHelpFlowTypesQuery = gql`
  query HelpFlowTypes {
    helpflowTypes {
      id
      displayName
    }
  }
`;

const CreateHelpRequestMutation = gql`
  mutation CreateHelpRequest(
    $helpflowType: String!
    $attributes: HelpRequestAttributes
  ) {
    createHelpRequest(
      input: { helpflowType: $helpflowType, attributes: $attributes }
    ) {
      success
      helpRequest {
        id
        createdAt
        description
        slackChannel
      }
      errors
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class HelpRequestService {
  constructor(
    private apollo: Apollo,
    private toastService: ToastMessageService,
    private patientSelectors: PatientSelectors,
  ) {}

  getHelpFlowTypes() {
    return this.apollo
      .query<HelpFlowTypeResponse>({
        query: GetHelpFlowTypesQuery,
      })
      .pipe(
        map(response => response.data),
        map((data: HelpFlowTypeResponse) =>
          this.mapHelpFlowTypesToDropdown(data.helpflowTypes),
        ),
      );
  }

  getUSStates(): Observable<DropdownItem[]> {
    return this.apollo
      .query<USStatesResponse>({
        query: GetUSStatesQuery,
      })
      .pipe(
        map(response => response.data),
        map(data => this.mapStatesToDropdown(data.states)),
      );
  }

  getLicensingBodyAndStates() {
    return combineLatest([
      this.patientSelectors.patient,
      this.getUSStates(),
    ]).pipe(
      switchMap(([patient, states]: [Patient, DropdownItem[]]) =>
        of({ licensingBodyId: patient.licensingBody.id, states }),
      ),
    );
  }

  getHelp(requestDetails: HelpRequestDetails) {
    const {
      details,
      options,
      helpflowType,
      licensingBodyId,
      callerOnTheLine,
      callbackName,
      callbackNumber,
      urgency,
      facility,
    } = requestDetails;
    const { actionUrl, linkedNoteId, patientId } = options;
    const variables = {
      helpflowType,
      attributes: {
        details,
        actionUrl,
        linkedNoteId,
        patientId,
        licensingBodyId,
        callerOnTheLine,
        callbackName,
        callbackNumber,
        urgency,
        facility,
      },
    };

    return this.apollo
      .mutate({
        mutation: CreateHelpRequestMutation,
        variables,
      })
      .pipe(
        tap((helpRequestResponse: HelpRequestMutationResponse) => {
          this.successToast(
            helpRequestResponse.data.createHelpRequest.helpRequest.slackChannel,
          );
        }),
        catchError(error => this.errorToast(error)),
      );
  }

  private mapHelpFlowTypesToDropdown(
    helpflowTypes: HelpFlowType[],
  ): DropdownItem[] {
    return helpflowTypes.map((type: HelpFlowType) => ({
      label: type.displayName,
      value: type.id,
    }));
  }

  private mapStatesToDropdown(usStates: USState[]): DropdownItem[] {
    return usStates.map(state => ({
      label: `${state.name} (${state.shortName})`,
      value: state.id,
    }));
  }

  private successToast(channelName: string) {
    this.toastService.add({
      severity: 'success',
      summary: 'Your request has been assigned',
      detail: `View your request in the <b>${channelName}</b> channel.`,
    });
  }

  private errorToast(error: any) {
    this.toastService.add({
      severity: 'error',
      summary: 'Your request is incomplete',
      detail: `${
        error.status === 500 ? `A server error occurred. ` : ''
      }Please try again`,
    });
    return error;
  }
}
