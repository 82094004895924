<p-toast [position]="position"
         data-cy="toast-message">
  <ng-template let-message
               pTemplate="message">
    <div om-layout
         om-layout-align="start center"
         om-layout-gutter
         class="p-toast-icon-container">
      <span class="p-toast-icon pi"
            [ngClass]="{
        'pi-info-circle': message.severity == 'info',
        'pi-exclamation-triangle':
          message.severity == 'warn' || message.severity == 'error',
        'pi-check': message.severity == 'success'
      }"></span>

      <div class="p-toast-message-text-content"
           om-layout="vertical"
           om-layout-gutter
           omgTheme>
        <div class="p-toast-summary"
             *ngIf="message.summary"
             [innerHTML]="message.summary"></div>
        <div class="p-toast-detail"
             *ngIf="message.detail"
             om-layout="vertical">
          <div class="p-toast-detail-body"
               [innerHTML]="message.detail"></div>
          <a [routerLink]="message.data?.routerLink"
             *ngIf="message.data?.routerLink && message.data?.linkText">{{message.data.linkText}}</a>
        </div>
      </div>

    </div>
  </ng-template>
</p-toast>
