import { PatientAllergy } from '@app/modules/allergies/shared/allergies.type';
import { HealthBackground } from '@app/modules/health-background/shared/health-background.type';
import { HealthGoalsSectionLinkPayload } from '@app/modules/health-maintenance/shared/health-maintenance.type';
import {
  Problem,
  ProblemStatus,
} from '@app/modules/problems/shared/problems.type';
import { mapTodoResponseToEntity } from '@app/modules/todo/shared/todo-api-mappers';
import { TodoAssignee } from '@app/modules/todo/shared/todo.type';
import {
  VitalsByType,
  vitalTypesByLoincCode,
} from '@app/modules/vitals-data/shared/vitals-data.type';
import { sortBy } from '@app/utils';
import { camelCase } from '@app/utils';

import {
  DeleteServiceTicketItemResponse,
  SummaryAddendumResponse,
  SummaryAddendumTodoAssigneeResponse,
  SummaryAppointmentResponse,
  SummaryAssociationType,
  SummaryAttachableFileResponse,
  SummaryChronicCareBillingProgramResponse,
  SummaryHealthBackgroundResponse,
  SummaryHealthGoalResponse,
  SummaryHealthGoalScreeningResponse,
  SummaryMedicationResponse,
  SummaryPatientAllergiesResponse,
  SummaryProblemResponse,
  SummaryResponse,
  SummaryServiceTicketItemResponse,
  SummaryVaccinationResponse,
} from './summaries-api.type';
import {
  SummaryAddendumUpdateData,
  SummaryAssessedProblemsUpdateData,
  SummaryHealthBackgroundUpdateData,
  SummaryHealthGoalsUpdateData,
  SummaryMedicationsUpdateData,
  SummaryPatientAllergiesUpdateData,
  SummaryProblemsUpdateData,
  SummarySignUpdateData,
  SummaryUpdateData,
  SummaryVaccinationsUpdateData,
} from './summaries-update.type';
import { hasNoSignificantMedicalHistory } from './summaries-utils';
import {
  Summary,
  SummaryAddendum,
  SummaryAppointment,
  SummaryAttachableFile,
  SummaryChronicCareBillingPrograms,
  SummaryHealthBackground,
  SummaryHealthGoal,
  SummaryHealthGoalScreening,
  SummaryMedication,
  SummaryPatientAllergy,
  SummaryProblem,
  SummaryServiceTicketItem,
  SummaryVaccination,
} from './summaries.type';

export const mapSummaryVitalsResponse = (response: any): VitalsByType =>
  response
    .map(v => camelCase(v))
    .filter(v => vitalTypesByLoincCode[v.measurementType?.loincCode])
    .reduce((result, vital) => {
      result[vitalTypesByLoincCode[vital.measurementType.loincCode]] = vital;
      return result;
    }, {});

/* istanbul ignore next */
export const mapSummaryProblemResponseToEntity = (
  summaryProblems: SummaryProblemResponse[],
): SummaryProblem[] =>
  sortBy(
    summaryProblem => [
      summaryProblem.problemType.clinicalDescription.toLowerCase(),
    ],
    summaryProblems.map(summaryProblem => {
      const problemType = summaryProblem.problem_type || {};
      const problemCodeLocation = summaryProblem.problem_code_location || {};

      return {
        aAndP: summaryProblem.a_and_p,
        briefComment: summaryProblem.brief_comment,
        code: summaryProblem.code,
        id: summaryProblem.id,
        onset: summaryProblem.onset,
        problemHistoryId: summaryProblem.problem_history_id,
        problemType: {
          clinicalDescription: problemType.clinical_description,
          id: problemType.id,
          importance: problemType.importance,
        },
        problemCodeLocation: {
          id: problemCodeLocation.id,
          name: problemCodeLocation.name,
        },
        resolution: summaryProblem.resolution,
        summary: summaryProblem.summary,
        // the summaryProblem can be created by the provider without a problem property on it initially.
        // In this case, unconfirmed is false since it's not patient reported but created by the provider
        unconfirmed:
          summaryProblem.problem?.status === ProblemStatus.UNCONFIRMED,
        status: summaryProblem.problem?.status,
      };
    }),
  );

/* istanbul ignore next */
export const mapSummaryHealthGoalScreeningsResponseToEntity = (
  screenings: SummaryHealthGoalScreeningResponse[],
): SummaryHealthGoalScreening[] =>
  screenings.map(screening => {
    const event = screening.event || {};
    return {
      dateFuzzy: screening.date_fuzzy,
      event: {
        id: event.id,
        name: event.name,
      },
      id: screening.id,
      result: screening.result,
    };
  });

export const mapSummaryServiceTicketReponsesToEntity = (
  serviceTicketItems: SummaryServiceTicketItemResponse[],
): SummaryServiceTicketItem[] =>
  serviceTicketItems.map(serviceTicketItem => {
    return {
      id: serviceTicketItem.id,
      itemClass: serviceTicketItem.item_class,
      indications: serviceTicketItem.indications,
      cpts: serviceTicketItem.cpts,
      summary: serviceTicketItem.summary,
      title: serviceTicketItem.title,
      hideFromNote: serviceTicketItem.hide_from_note,
    };
  });

/* istanbul ignore next */
export const mapSummaryHealthGoalResponseToEntity = (
  healthGoals: SummaryHealthGoalResponse[],
): SummaryHealthGoal[] =>
  healthGoals.map(healthGoal => {
    const healthGoalAction = healthGoal.health_goal_action || {};
    const healthGoalScreenings = healthGoal.health_goal_screenings || [];
    const healthGoalType = healthGoal.health_goal_type || {};

    return {
      comment: healthGoal.comment,
      declined: healthGoal.declined,
      dueAt: healthGoal.due_at,
      healthGoalAction: {
        id: healthGoalAction.id,
        name: healthGoalAction.name,
        uniqueName: healthGoalAction.unique_name,
      },
      healthGoalHistoryId: healthGoal.health_goal_history_id,
      healthGoalScreenings: mapSummaryHealthGoalScreeningsResponseToEntity(
        healthGoalScreenings,
      ),
      healthGoalType: {
        active: healthGoalType.active,
        decisionSupport: healthGoalType.decision_support,
        id: healthGoalType.id,
        name: healthGoalType.name,
      },
      id: healthGoal.id,
      indicated: healthGoal.indicated,
    };
  });

/* istanbul ignore next */
export const mapSummaryPatientAllergiesResponseToEntity = (
  patientAllergies: SummaryPatientAllergiesResponse[],
): SummaryPatientAllergy[] =>
  patientAllergies.map(patientAllergy => ({
    comment: patientAllergy.comment,
    id: patientAllergy.id,
    name: patientAllergy.name,
    reaction: patientAllergy.reaction,
  }));

/* istanbul ignore next */
export const mapSummaryMedicationsToEntity = (
  medications: SummaryMedicationResponse[],
): SummaryMedication[] =>
  sortBy(
    medication => [medication.name.toLowerCase()],
    medications.map(medication => ({
      id: medication.id,
      name: medication.name,
      regimen: medication.regimen,
    })),
  );

/* istanbul ignore next */
export const mapSummaryVaccinationsToEntity = (
  vaccinations: SummaryVaccinationResponse[],
): SummaryVaccination[] =>
  vaccinations.map(vaccination => ({
    givenOnWithPrecision: vaccination.given_on_with_precision,
    id: vaccination.id,
    name: vaccination.name,
    step: vaccination.step,
  }));

/* istanbul ignore next */
export const mapSummaryAttachableFilesToEntity = (
  attachableFiles: SummaryAttachableFileResponse[],
): SummaryAttachableFile[] =>
  attachableFiles.map(attachableFile => ({
    id: attachableFile.id,
    url: attachableFile.url,
    downloadPath: attachableFile.download_path,
    viewingPath: attachableFile.viewing_path,
    thumbViewingPath: attachableFile.thumb_viewing_path,
    name: attachableFile.name,
    size: attachableFile.size,
    type: attachableFile.type,
    lastModifiedAt: attachableFile.last_modified_at,
  }));

/* istanbul ignore next */
export const mapSummaryHealthBackgroundResponseToEntity = (
  healthBackground: SummaryHealthBackgroundResponse,
): SummaryHealthBackground => {
  if (!healthBackground) {
    return null;
  }

  const familyData = healthBackground.family_data || { notes: null };
  const illnessesAndSurgeries = healthBackground.illnesses_and_surgeries || {
    notes: null,
  };
  const socialData = healthBackground.social_data || { notes: null };

  return {
    familyData: { notes: familyData.notes },
    healthBackgroundHistoryId: healthBackground.health_background_history_id,
    illnessesAndSurgeries: { notes: illnessesAndSurgeries.notes },
    socialData: { notes: socialData.notes },
  };
};

/* istanbul ignore next */
export const mapSummaryAddendumTodoAssigneeResponseToEntity = (
  assignee: SummaryAddendumTodoAssigneeResponse,
): TodoAssignee => ({
  id: assignee.id,
  identifier: assignee.identifier,
  name: assignee.name,
  role: assignee.role,
  label: assignee.name ? `${assignee.name} (${assignee.role})` : '',
});

/* istanbul ignore next */
export const mapSummaryAddendumResponseToEntity = (
  addendum: SummaryAddendumResponse,
): SummaryAddendum => {
  const summary = addendum.summary || { todos: [] };
  const summaryTodos = summary.todos;

  return {
    id: addendum.id,
    content: addendum.content,
    signedAt: addendum.signed_at,
    coSigned: addendum.co_signed,
    signedByName: addendum.signed_by_name,
    signedByRole: addendum.signed_by_role,
    summary: {
      todos: summaryTodos.map(todo =>
        mapTodoResponseToEntity(todo, addendum.id, SummaryAssociationType),
      ),
    },
  };
};

/* istanbul ignore next */
export const mapSummaryAppointmentResponseToEntity = (
  appointment: SummaryAppointmentResponse,
): SummaryAppointment => ({
  id: appointment.id,
  reason: appointment.reason,
  startAt: appointment.start_at,
  endAt: appointment.end_at,
  duration: appointment.duration,
  type: appointment.type,
  avs_visible: appointment.avs_visible,
  cosignatureRequired: appointment.cosignature_required,
});

/* istanbul ignore next */
export const mapSummaryChronicCareBillingProgramsToEntity = (
  programs: SummaryChronicCareBillingProgramResponse[],
): SummaryChronicCareBillingPrograms[] =>
  programs?.map(program => ({
    id: program.id,
    displayName: program.display_name,
  }));

/* istanbul ignore next */
export const mapSummaryResponseToEntity = (
  response: SummaryResponse,
): Summary => {
  const createdBy = response.created_by || {};
  const signedBy = response.signed_by || {};
  const note = response.note || {};
  const noteType = response.note_type || {};
  const vitals = response.vitals || [];
  const healthMaintenanceNote = response.health_maintenance_note || {};
  const healthGoals = response.health_goals || [];
  const healthBackground = response.health_background || null;
  const activeProblems = response.active_problems || [];
  const resolvedProblems = response.resolved_problems || [];
  const patientAllergies = response.patient_allergies || [];
  const medications = response.medications || [];
  const vaccinations = response.vaccinations || [];
  const cosignTaskAssignee = response.cosign_task_assignee || {};
  const appointment = response.appointment || null;
  const summaryAddendums = response.summary_addendums || [];
  const redactedBy = response.redacted_by || {};
  const summaryProviderRecommendation =
    response.summary_provider_recommendation || {};

  return {
    id: response.id || null,
    patientId: response.patient_id,
    subjective: response.subjective,
    objective: response.objective,
    signed: response.signed,
    subject: response.subject,
    createdAt: response.created_at,
    medicalHistory: response.medical_history,
    createdBy: {
      id: createdBy.id,
      name: createdBy.name,
    },
    signedAt: response.signed_at || null,
    signedBy: {
      id: signedBy.id,
      name: signedBy.name,
    },
    timeBasedVisit: response.time_based_visit,
    note: {
      id: note.id,
      totalComments: note.total_comments,
    },
    noteType: {
      name: noteType.name,
      systemName: noteType.system_name,
      timeBasedVisitBillable: noteType.time_based_visit_billable,
      tags: [...noteType.tags],
    },
    attachableFiles: mapSummaryAttachableFilesToEntity(
      response.attachable_files,
    ),
    vitals: mapSummaryVitalsResponse(vitals),
    activeProblems: mapSummaryProblemResponseToEntity(activeProblems),
    resolvedProblems: mapSummaryProblemResponseToEntity(
      response.resolved_problems,
    ),
    assessedProblems: mapSummaryProblemResponseToEntity(
      response.assessed_problems,
    ),
    serviceTicketItems: mapSummaryServiceTicketReponsesToEntity(
      response.service_ticket_items,
    ),
    visitProcedureId: response.visit_procedure_id,
    healthGoals: mapSummaryHealthGoalResponseToEntity(healthGoals),
    healthMaintenanceNote: {
      content: healthMaintenanceNote.content,
      healthMaintenanceNoteHistoryId:
        healthMaintenanceNote.health_maintenance_note_history_id,
    },
    healthBackground: mapSummaryHealthBackgroundResponseToEntity(
      healthBackground,
    ),
    noKnownAllergies: response.no_known_allergies,
    patientAllergies: mapSummaryPatientAllergiesResponseToEntity(
      patientAllergies,
    ),
    noMedications: response.no_medications,
    medications: mapSummaryMedicationsToEntity(medications),
    vaccinations: mapSummaryVaccinationsToEntity(vaccinations),
    summaryAddendums: summaryAddendums.map(mapSummaryAddendumResponseToEntity),
    createdForInfant: response.created_for_infant,
    createdForChild: response.created_for_child,
    noSignificantMedicalHistory: hasNoSignificantMedicalHistory(
      activeProblems.length,
      resolvedProblems.length,
    ),
    cosignTaskAssignee: {
      id: cosignTaskAssignee.id,
      identifier: cosignTaskAssignee.identifier,
      name: cosignTaskAssignee.name,
      outUntil: cosignTaskAssignee.out_until,
      role: cosignTaskAssignee.role,
    },
    appointment: appointment
      ? mapSummaryAppointmentResponseToEntity(appointment)
      : null,
    redactedAt: response.redacted_at,
    redactedBy: {
      id: redactedBy.id,
      name: redactedBy.name,
    },
    billingProcedureSuggestions: response.billing_procedure_suggestions,
    summaryProviderRecommendation: {
      id: summaryProviderRecommendation.id,
      body: summaryProviderRecommendation.body,
      subjectLine: summaryProviderRecommendation.subject_line,
      published: summaryProviderRecommendation.published,
      s3Pointers:
        summaryProviderRecommendation.s3_pointers &&
        summaryProviderRecommendation.s3_pointers.map(pointer => ({
          id: pointer.id,
          bucket: pointer.bucket,
          key: pointer.key,
          contentLength: pointer.content_length,
          contentType: pointer.content_type,
          title: pointer.title,
        })),
    },
    hasProgramVisit: response.has_program_visit,
    chronicCareBillingPrograms: mapSummaryChronicCareBillingProgramsToEntity(
      response.chronic_care_billing_programs,
    ),
  };
};

/* Request Mappers */

/* istanbul ignore next */
export const mapToSummaryProblemsRequest = (
  problems: Problem[],
): SummaryProblemsUpdateData => {
  const problemsIncludedInMedicalHistory = problems.filter(
    includedProblem =>
      includedProblem.includedInMedicalHistory && !includedProblem.suspected,
  );
  const activeProblems = problemsIncludedInMedicalHistory.filter(
    item => item.active || item.status === ProblemStatus.UNCONFIRMED,
  );
  const resolvedProblems = problemsIncludedInMedicalHistory.filter(
    item => !item.active && item.status !== ProblemStatus.UNCONFIRMED,
  );

  return {
    active_problem_history_ids: activeProblems.map(
      activeProblem => activeProblem.problemHistoryId,
    ),
    resolved_problem_history_ids: resolvedProblems.map(
      resolvedProblem => resolvedProblem.problemHistoryId,
    ),
    medical_history: true,
  };
};

/* istanbul ignore next */
export const mapToSummaryPatientAllergiesRequest = (
  activeAllergies: PatientAllergy[],
): SummaryPatientAllergiesUpdateData => {
  const noKnownAllergies = activeAllergies.length === 0;
  const historyIds = activeAllergies.map(
    activeAllergy => activeAllergy.patientAllergyHistoryId,
  );

  return {
    no_known_allergies: noKnownAllergies,
    patient_allergy_history_ids: [...historyIds],
  };
};

/* istanbul ignore next */
export const mapToSummaryHealthBackgroundUpdateRequest = (
  healthBackground: HealthBackground,
): SummaryHealthBackgroundUpdateData => ({
  health_background_history_id: healthBackground.healthBackgroundHistoryId,
});

/* istanbul ignore next */
export const mapToSummaryHealthGoalsUpdateRequest = (
  healthGoals: HealthGoalsSectionLinkPayload,
): SummaryHealthGoalsUpdateData => ({
  health_goal_history_ids: healthGoals.healthGoalHistoryIds,
  health_goal_screening_history_ids: healthGoals.healthGoalScreeningHistoryIds,
  health_maintenance_note_history_id:
    healthGoals.healthMaintenanceNoteHistoryId,
});

/* istanbul ignore next */
export const mapToSummaryMedicationsUpdateRequest = (
  noMedications: boolean,
  regimenIDs: number[],
): SummaryMedicationsUpdateData => ({
  no_medications: noMedications,
  patient_medication_regimen_ids: [...regimenIDs],
});

/* istanbul ignore next */
export const mapToSummaryVaccinationsUpdateRequest = (
  historyIds: number[],
): SummaryVaccinationsUpdateData => ({
  vaccine_history_ids: [...historyIds],
});

export const mapToSummaryAssessedProblemsUpdateRequest = (
  historyIds: number[],
): SummaryAssessedProblemsUpdateData => {
  return {
    assessed_problem_history_ids: [...historyIds],
  };
};

/* istanbul ignore next */
export const mapToSummarySignUpdateRequest = (
  summary: Summary,
): SummarySignUpdateData => {
  const cosignTaskAssignee = summary.cosignTaskAssignee;
  return {
    id: summary.id,
    patientId: summary.patientId,
    cosign_task_assignee: {
      id: cosignTaskAssignee.id,
      identifier: cosignTaskAssignee.identifier,
      name: cosignTaskAssignee.name,
      out_until: cosignTaskAssignee.outUntil,
      role: cosignTaskAssignee.role,
    },
  };
};

/* istanbul ignore next */
export const mapToSummaryAddendumUpdateRequest = (
  summaryId: number,
  content: string,
): SummaryAddendumUpdateData => ({
  content,
  summary_id: summaryId,
});

/* istanbul ignore next */
export const deleteServiceTicketItemResponse = (
  response: DeleteServiceTicketItemResponse,
): any => {
  return response.deleteServiceTicketItem;
};
