import { vitalTypes } from '../vitals-data.type';
import { getChildBMIPercentile } from './child-body-mass-index-percentile';
import { getChildHeightPercentile } from './child-height-percentile';
import { getChildWeightPercentile } from './child-weight-percentile';
import { getDownSyndromeChildBMIPercentile } from './down-syndrome-child-bmi-percentile';
import { getDownSyndromeHeightPercentile } from './down-syndrome-child-height-percentile';
import { getDownSyndromeChildWeightPercentile } from './down-syndrome-child-weight-percentile';
import { getDownSyndromeHeadCircPercentile } from './down-syndrome-head-circumference-percentile';
import { getDownSyndromeInfantHeightPercentile } from './down-syndrome-infant-length-percentile';
import { getDownSyndromeInfantWeightPercentile } from './down-syndrome-infant-weight-percentile';
import { getHeadCircPercentile } from './infant-head-circumference-percentile';
import { getInfLenPercentile } from './infant-length-percentile';
import { getInfWtPercentile } from './infant-weight-percentile';

export interface GrowthFieldConfig {
  percentileVitalType: string;
  percentileFn: Function;

  // non-editable meaning it does not need to be updated on change
  nonEditableField?: boolean;
}

export interface GrowthFieldToConfigMapping {
  [inputFieldName: string]: GrowthFieldConfig;
}

export const infantFieldToConfigMapping: GrowthFieldToConfigMapping = {
  [vitalTypes.weight]: {
    percentileVitalType: vitalTypes.weightPercentile,
    percentileFn: getInfWtPercentile,
  },
  [vitalTypes.height]: {
    percentileVitalType: vitalTypes.heightPercentile,
    percentileFn: getInfLenPercentile,
  },
  [vitalTypes.headCircumference]: {
    percentileVitalType: vitalTypes.headCircumferencePercentile,
    percentileFn: getHeadCircPercentile,
  },
};

// NOTE: BMI & bmi_pct is handled separately since it's a calculated value
export const childFieldToConfigMapping: GrowthFieldToConfigMapping = {
  [vitalTypes.weight]: {
    percentileVitalType: vitalTypes.weightPercentile,
    percentileFn: getChildWeightPercentile,
  },
  [vitalTypes.height]: {
    percentileVitalType: vitalTypes.heightPercentile,
    percentileFn: getChildHeightPercentile,
  },
  [vitalTypes.bodyMassIndex]: {
    percentileVitalType: vitalTypes.bmiPercentile,
    percentileFn: getChildBMIPercentile,
    nonEditableField: true,
  },
};

export const dsInfantFieldToConfigMapping: GrowthFieldToConfigMapping = {
  [vitalTypes.weight]: {
    percentileVitalType: vitalTypes.weightPercentile,
    percentileFn: getDownSyndromeInfantWeightPercentile,
  },
  [vitalTypes.height]: {
    percentileVitalType: vitalTypes.heightPercentile,
    percentileFn: getDownSyndromeInfantHeightPercentile,
  },
  [vitalTypes.headCircumference]: {
    percentileVitalType: vitalTypes.headCircumferencePercentile,
    percentileFn: getDownSyndromeHeadCircPercentile,
  },
};

// NOTE: BMI & bmi_pct is handled separately since it's a calculated value
export const dsChildFieldToConfigMapping: GrowthFieldToConfigMapping = {
  [vitalTypes.weight]: {
    percentileVitalType: vitalTypes.weightPercentile,
    percentileFn: getDownSyndromeChildWeightPercentile,
  },
  [vitalTypes.height]: {
    percentileVitalType: vitalTypes.heightPercentile,
    percentileFn: getDownSyndromeHeightPercentile,
  },
  [vitalTypes.bodyMassIndex]: {
    percentileVitalType: vitalTypes.bmiPercentile,
    percentileFn: getDownSyndromeChildBMIPercentile,
    nonEditableField: true,
  },
};
