import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

export type BannerVariant =
  | 'info'
  | 'instructional'
  | 'error'
  | 'warning'
  | 'success';
export type MessageVariant = 'without-padding';

@Component({
  selector: 'omg-collapsible-banner',
  templateUrl: './collapsible-banner.component.html',
  styleUrls: ['./collapsible-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleBannerComponent implements OnInit {
  @Input() variant: BannerVariant;
  @Input() messageVariant: MessageVariant;
  @Input() expanded: boolean;
  @Input() disabled: boolean;

  constructor() {}

  ngOnInit() {}

  get variantClass(): string {
    const clickableClass = !this.disabled ? ' clickable' : '';
    return `-${this.variant}${clickableClass}`;
  }
}
