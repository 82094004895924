import { TodoResponse } from './todo-api.type';
import { TaskAssigneeResult, Todo } from './todo.type';

/* istanbul ignore next */
export const mapTodoResponseToEntity = (
  data: TodoResponse,
  associatedWithId: number,
  associatedWithType: string,
): Todo => {
  if (!data) {
    return {
      assignee: {
        id: null,
        identifier: null,
        name: null,
        role: null,
        label: null,
      },
      associatedWithId: null,
      associatedWithType: null,
      comments: null,
      highPriority: null,
      id: null,
      name: null,
      ownerId: null,
      state: null,
      isManual: null,
      finishLabel: null,
      finishedAt: null,
      finishedBy: null,
    };
  }

  const assignee = data.assignee || {};
  const finishedBy = data.finished_by || {};

  return {
    assignee: {
      id: assignee.id,
      identifier: assignee.identifier,
      name: assignee.name,
      role: assignee.role,
      label: assignee.name ? `${assignee.name} (${assignee.role})` : '',
    },
    associatedWithId,
    associatedWithType,
    comments: data.comments,
    highPriority: data.high_priority,
    id: data.id,
    name: data.name,
    ownerId: data.owner_id,
    state: data.state,
    isManual: data.is_manual,
    finishLabel: data.finish_label,
    finishedAt: data.finished_at,
    finishedBy: {
      id: finishedBy.id,
      name: finishedBy.name,
      role: finishedBy.role,
    },
    categoryId: data.category_id,
    relatedTaskTemplates: data.related_task_templates,
    taskTemplateId: data.task_template_id,
    taskTemplateIsChangeable: data.task_template_is_changeable,
    nextTaskTemplates: data.next_task_templates,
    nextStepId: data.next_step_id,
  };
};

/* istanbul ignore next */
export const mapTodoEntityToUpdateData = (data: Todo): TodoResponse => {
  const assignee = data.assignee;

  return {
    assignee: {
      id: assignee.id,
      identifier: assignee.identifier,
      name: assignee.name,
      role: assignee.role,
    },
    comments: data.comments,
    high_priority: data.highPriority,
    id: data.id,
    name: data.name,
    owner_id: data.ownerId,
    state: data.state,
    via_note: data.viaNote || null,
    task_template_id: data.taskTemplateId || null,
    next_task_template_id: data.nextTaskTemplateId,
  };
};

export const mapTaskAssigneeSearchResultToEntity = (
  data: any,
): TaskAssigneeResult => ({
  ...data._source,
  databaseId: data._source.database_id,
  outUntil: data._source.out_until,
  label: `${data._source.name} (${data._source.role})`,
});
