<span [omgPopoverTriggerFor]="providerInfoPopover"
      omgPopoverTriggerOn="hover">
  <ng-content></ng-content>
  <omg-popover #providerInfoPopover
               [placement]="placement"
               [offsetX]="offsetX"
               [offsetY]="offsetY"
               data-cy="provider-info-popover">
    <omg-internal-user-info-popover [providerId]="providerId"
                                    (updated)="resize()"></omg-internal-user-info-popover>
  </omg-popover>
</span>
