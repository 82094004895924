/* Convert lbs/inches^2 to kg/m^2. */
const conversionFactor = 703;

export const getBmi = (weight: number, height: number) =>
  (weight / (height * height)) * conversionFactor;

export const convertAgeToHalfMonths = (ageInMonths: number) =>
  Math.floor(ageInMonths) + 0.5;

export const convertAgeToHalfYearInMonths = (ageInMonths: number) =>
  (ageInMonths * 2.0) / 12 / 2;
