import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { combineLatest, iif, Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { isInfant, isMinor } from '@app/core/patient/shared/patient-utils';
import { Patient } from '@app/core/patient/shared/patient.type';
import { hasDownSyndrome } from '@app/modules/growth-charts/growth-charts/growth-charts-additional-options';
import { ProblemsApiService } from '@app/modules/problems/shared/problems-api.service';
import { vitalTypes } from '@app/modules/vitals-data/shared/vitals-data.type';
import { getBmi } from '@app/modules/vitals-data/shared/vitals-utils';
import { filterTruthy, ordinalizePercentile } from '@app/utils';

import { MeasurementValidationRules } from '../shared/measurement-validation-rules.type';
import {
  childFieldToConfigMapping,
  dsChildFieldToConfigMapping,
  dsInfantFieldToConfigMapping,
  infantFieldToConfigMapping,
} from '../shared/percentile-calculator';

@Component({
  selector: 'omg-vital-set-form',
  templateUrl: './vital-set-form.component.html',
  styleUrls: ['./vital-set-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VitalSetFormComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() validationRules: MeasurementValidationRules;
  @Input() patient: Patient;

  gender: string;
  ageInMonths: number;
  isMinor: boolean;
  isInfant: boolean;
  weight: any;
  downSyndrome: boolean;

  unsubscribe: Subject<void> = new Subject();

  weight$: Observable<number>;
  height$: Observable<number>;
  headCircumference$: Observable<number>;
  bodyMassIndex$: Observable<number>;

  weightPercentile$: Observable<number>;
  heightPercentile$: Observable<number>;
  headCircumferencePercentile$: Observable<number>;
  bmiPercentile$: Observable<number>;

  ordinalizedWeightPercentile$: Observable<string>;
  ordinalizedHeightPercentile$: Observable<string>;
  ordinalizedHeadCircumferencePercentile$: Observable<string>;
  ordinalizedBmiPercentile$: Observable<string>;

  downsFlag = false;
  downSyndromePercentileEnabled$;
  downSyndromeProblemType$;

  constructor(
    private problemsApi: ProblemsApiService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit(): void {
    this.isInfant = isInfant(this.patient);
    this.isMinor = isMinor(this.patient);
    this.ageInMonths = this.patient.ageInMonths;
    this.gender = this.patient.gender;

    if (this.isInfant) {
      this.weight = {
        key: vitalTypes.infantWeight,
        unit: 'kg',
        label: 'infant wt',
        step: 0.01,
      };
    } else {
      this.weight = {
        key: vitalTypes.weight,
        unit: 'lb',
        label: 'wt',
        step: 0.1,
      };
    }

    this.downSyndromePercentileEnabled$ = this.launchDarklyService.variation$(
      FeatureFlagNames.growthChartsDownSyndrome,
      false,
    );

    this.downSyndromeProblemType$ = this.downSyndromePercentileEnabled$.pipe(
      switchMap(enabled => {
        return enabled
          ? this.problemsApi.query(this.patient.id).pipe(
              take(1),
              map(problems => {
                if (hasDownSyndrome(problems)) {
                  this.downsFlag = true;
                } // check preterm here
              }),
            )
          : of('');
      }),
    );

    this.downSyndromeProblemType$.pipe(take(1)).subscribe(() => {
      this.setupForm();
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get growthFieldConfig() {
    if (this.downsFlag && this.isInfant) {
      return dsInfantFieldToConfigMapping;
    } else if (this.downsFlag) {
      return dsChildFieldToConfigMapping;
    } else if (this.isInfant) {
      return infantFieldToConfigMapping;
    } else if (this.isMinor) {
      return childFieldToConfigMapping;
    }
    return {};
  }

  private setupForm(): void {
    this.weight$ = this.form
      .get(this.weight.key)
      .valueChanges.pipe(startWith(this.form.value[this.weight.key]));
    this.height$ = this.form
      .get(vitalTypes.height)
      .valueChanges.pipe(startWith(this.form.value[vitalTypes.height]));
    this.headCircumference$ = this.form
      .get(vitalTypes.headCircumference)
      .valueChanges.pipe(
        startWith(this.form.value[vitalTypes.headCircumference]),
      );

    [
      this.weightPercentile$,
      this.ordinalizedWeightPercentile$,
    ] = this.percentileStream$(this.weight$, vitalTypes.weight);

    [
      this.heightPercentile$,
      this.ordinalizedHeightPercentile$,
    ] = this.percentileStream$(this.height$, vitalTypes.height);

    [
      this.headCircumferencePercentile$,
      this.ordinalizedHeadCircumferencePercentile$,
    ] = this.percentileStream$(
      this.headCircumference$,
      vitalTypes.headCircumference,
    );

    this.bodyMassIndex$ = combineLatest([this.weight$, this.height$]).pipe(
      takeUntil(this.unsubscribe),
      map(([weight, height]) => {
        if (this.isInfant) {
          return null;
        } else {
          const bmi = Number(getBmi(weight, height).toFixed(1));
          return bmi > 0 && Number.isFinite(bmi) ? bmi : null;
        }
      }),
    );

    this.bodyMassIndex$.subscribe(bmi => {
      this.form.get(vitalTypes.bodyMassIndex).setValue(bmi, {
        emitEvent: false,
        emitModelToViewChange: true,
      });
    });

    [
      this.bmiPercentile$,
      this.ordinalizedBmiPercentile$,
    ] = this.percentileStream$(this.bodyMassIndex$, vitalTypes.bodyMassIndex);
  }

  private percentileStream$(
    vital$: Observable<number>,
    vitalType: string,
  ): [Observable<number>, Observable<string>] {
    const { percentileFn, percentileVitalType } =
      this.growthFieldConfig[vitalType] || {};

    if (!percentileFn || !percentileVitalType) {
      return [of(null), of(null)];
    }

    const percentile$ = vital$.pipe(
      map(value =>
        value ? percentileFn(this.gender, this.ageInMonths, value) : null,
      ),
    );

    percentile$.subscribe(value =>
      this.form.controls[percentileVitalType].setValue(value),
    );

    const ordinalizedPercentile$ = percentile$.pipe(
      map(value => ordinalizePercentile(value)),
    );

    return [percentile$, ordinalizedPercentile$];
  }
}
