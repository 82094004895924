import { FormControl, FormGroup, Validators } from '@angular/forms';

import {
  VitalsByType,
  vitalTypes,
} from '@app/modules/vitals-data/shared/vitals-data.type';
import { wholeNumberValidator } from '@app/shared';

import { MeasurementValidationRules } from './measurement-validation-rules.type';

export class VitalSetFormService {
  constructor() {}

  buildForm(
    vitals: VitalsByType = {},
    validationRules: MeasurementValidationRules,
  ): FormGroup {
    return new FormGroup({
      [vitalTypes.systolicPressure]: new FormControl(
        vitals[vitalTypes.systolicPressure]?.value,
        [
          Validators.pattern(/[0-9]{0,3}/),
          Validators.min(validationRules.systolicPressure.min),
          Validators.max(validationRules.systolicPressure.max),
          wholeNumberValidator(),
        ],
      ),
      [vitalTypes.diastolicPressure]: new FormControl(
        vitals[vitalTypes.diastolicPressure]?.value,
        [
          Validators.pattern(/[0-9]{0,3}/),
          Validators.min(validationRules.diastolicPressure.min),
          Validators.max(validationRules.diastolicPressure.max),
          wholeNumberValidator(),
        ],
      ),
      [vitalTypes.heartRate]: new FormControl(
        vitals[vitalTypes.heartRate]?.value,
        [
          Validators.pattern(/[0-9]{0,3}/),
          Validators.min(validationRules.heartRate.min),
          Validators.max(validationRules.heartRate.max),
          wholeNumberValidator(),
        ],
      ),
      [vitalTypes.respiratoryRate]: new FormControl(
        vitals[vitalTypes.respiratoryRate]?.value,
        [
          Validators.pattern(/[0-9]{0,3}/),
          Validators.min(validationRules.respiratoryRate.min),
          Validators.max(validationRules.respiratoryRate.max),
          wholeNumberValidator(),
        ],
      ),
      [vitalTypes.temperature]: new FormControl(
        vitals[vitalTypes.temperature]?.value,
        [
          Validators.pattern(/([0-9]{1,3}(\.[0-9]?)?)?/),
          Validators.min(validationRules.temperature.min),
          Validators.max(validationRules.temperature.max),
        ],
      ),
      [vitalTypes.weight]: new FormControl(vitals[vitalTypes.weight]?.value, [
        Validators.pattern(/([0-9]{1,3}(.[0-9]?)?)?/),
        Validators.min(0),
        Validators.max(999),
      ]),
      [vitalTypes.infantWeight]: new FormControl(
        vitals[vitalTypes.infantWeight]?.value,
        [
          Validators.pattern(/([0-9]{0,3}(.[0-9]{0,2})?)?/),
          Validators.min(0),
          Validators.max(500),
        ],
      ),
      [vitalTypes.weightPercentile]: new FormControl(
        vitals[vitalTypes.weightPercentile]?.value,
      ),
      [vitalTypes.height]: new FormControl(vitals[vitalTypes.height]?.value, [
        Validators.pattern(/([0-9]{1,2}(.[0-9]?)?)?/),
        Validators.min(0),
        Validators.max(108),
      ]),
      [vitalTypes.heightPercentile]: new FormControl(
        vitals[vitalTypes.heightPercentile]?.value,
      ),
      [vitalTypes.bodyMassIndex]: new FormControl(
        vitals[vitalTypes.bodyMassIndex]?.value,
        [
          Validators.pattern(/([0-9]{1,3}(.[0-9]?)?)?/),
          Validators.min(0),
          Validators.max(99),
        ],
      ),
      [vitalTypes.bmiPercentile]: new FormControl(
        vitals[vitalTypes.bmiPercentile]?.value,
      ),
      [vitalTypes.headCircumference]: new FormControl(
        vitals[vitalTypes.headCircumference]?.value,
        [
          Validators.pattern(/([0-9]{0,2}(.[0-9]?)?)?/),
          Validators.min(0),
          Validators.max(99.9),
        ],
      ),
      [vitalTypes.headCircumferencePercentile]: new FormControl(
        vitals[vitalTypes.headCircumferencePercentile]?.value,
      ),
      [vitalTypes.pulseOximetry]: new FormControl(
        vitals[vitalTypes.pulseOximetry]?.value,
        [Validators.min(0), Validators.max(100)],
      ),
      [vitalTypes.oxygenConcentration]: new FormControl(
        vitals[vitalTypes.oxygenConcentration]?.value,
        [Validators.min(0), Validators.max(100)],
      ),
    });
  }
}
