import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnInit,
  Optional,
  TemplateRef,
} from '@angular/core';
import { flow, isPlainObject, keys, pickBy, without } from 'lodash/fp';
import { interval, Observable } from 'rxjs';
import { debounce } from 'rxjs/operators';

import {
  FormModel,
  FormModelState,
  FormModelStateChange,
  FormModelStateValues,
} from './form-model';
import { FormDirective } from './form.directive';

type FormStateCondition =
  | FormModelState
  | FormModelState[]
  | { [k in FormModelState]: any };

type FormStateSettingMap = {
  [k in FormModelState]?: {
    message?: string;
    debounceTime?: number;
  };
};

const stateSettingDefaults: FormStateSettingMap = {
  pristine: { message: '' },
  savePending: { message: '' },
  saving: { message: 'Saving . . .', debounceTime: 1500 },
  saved: { message: 'Saved' },
  saveError: { message: 'Error!' },
};

@Component({
  exportAs: 'omgFormState',
  selector: 'omg-form-state, [omgFormState]',
  styleUrls: ['./form-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './form-state.component.html',
})
export class FormStateComponent implements OnInit {
  private currentState: FormModelState;
  stateChanges: Observable<FormModelStateChange>;

  @Input() model: FormModel;
  @Input() when: FormStateCondition;
  @Input() not: FormStateCondition;
  @Input() stateOptions: FormStateSettingMap = stateSettingDefaults;

  @ContentChild(TemplateRef)
  stateTemplate: TemplateRef<any>;

  constructor(@Optional() private formDirective: FormDirective) {}

  ngOnInit() {
    this.setFormModel(
      (this.formDirective && this.formDirective.model) || this.model,
    );
  }

  stateCondition(state: FormModelState) {
    const statesToInclude = without(
      this.parseStateCondition(this.not) || [],
      this.parseStateCondition(this.when) || FormModelStateValues,
    );
    return statesToInclude.includes(state);
  }

  stateMessage(state: FormModelState) {
    const stateSettings = this.stateOptions[state] || {};
    return stateSettings.message;
  }

  private setFormModel(newModel: FormModel) {
    this.model = newModel;

    if (!this.model) {
      return;
    }
    this.stateChanges = this.model.stateChanges.pipe(
      debounce(c => this.nextDebounceTime(c.state)),
    );
  }

  private nextDebounceTime(nextState: FormModelState) {
    const current = this.stateOptions[this.currentState] || {};
    this.currentState = nextState;
    return current.debounceTime ? interval(current.debounceTime) : interval(0);
  }

  private parseStateCondition(
    stateCondition: FormStateCondition,
  ): FormStateCondition[] {
    if (typeof stateCondition === 'string') {
      return stateCondition.split(/\s+/) as FormStateCondition[];
    } else if (Array.isArray(stateCondition)) {
      return stateCondition;
    } else if (isPlainObject(stateCondition)) {
      return flow(
        pickBy(val => val),
        keys,
      )(stateCondition) as any;
    }

    return null;
  }
}
